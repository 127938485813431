import React, { useEffect } from 'react';
import { Dashboard } from './components/Dashboard';
import { Home } from './components/Home';

import './custom.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { Route, Routes } from 'react-router-dom';
import { Ticket } from './components/Ticket';
import { AuthProvider } from 'react-oidc-context';
import { RequireAuth } from './components/RequireAuth';
import { WebStorageStateStore } from 'oidc-client-ts';
import { getAppUrl, getAuthorityUrl, getTitle } from './utils/env';


const isModeLimited = process.env.REACT_APP_BUILD_MODE === 'limited';
const authority = getAuthorityUrl();
const appUri = getAppUrl();
const title = getTitle();

const oidcConfig = {
  authority,
  client_id: "interactive",
  redirect_uri: appUri,
  post_logout_redirect_uri: appUri,
  scope: 'openid profile offline_access',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: (_user) => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  }
};

const App = () => {

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <AuthProvider {...oidcConfig}>
      <Routes>
        {
          !isModeLimited ?
            <Route exact path='/' element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            } /> :
            <Route exact path='/' element={<Home />} />
        }
        <Route path='/_k/:key' element={<Ticket />} />
      </Routes>
    </AuthProvider>
  );
}

  export default App;