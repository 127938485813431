import React from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

export const WQLocation = ({ title, ipAddress, isOnline, totalWaiting, openCounters, totalFinished, avgProcessedTime }) => {
  return (
    <Card className="mb-3">
      <CardHeader className="d-flex flex-stack">
        <CardTitle className="location-title" tag="h5" title={title}>
          {title}
        </CardTitle>
        {isOnline ?
          <span className="badge  bg-success">online</span> :
          <span className="badge  bg-danger">offline</span>
        }

      </CardHeader>
      <CardBody>
        <ListItem label="Общо чакащи" value={totalWaiting} />
        <ListSeparator />
        <ListItem label="Работещи гишета" value={openCounters} />
        <ListSeparator />
        <ListItem label="Обслужени за деня" value={totalFinished} />
        <ListSeparator />
        <ListItem label="Време за обслужване" value={avgProcessedTime} />
        <div className="mt-3">
          <Button
            href={`http://${ipAddress}/Account/LoginSSO`}
            target="_blank"
            tag="a"
            color="success"
            outline>
            <i className="bi bi-box-arrow-up-right"></i>
            {' '}Настройки
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}

const ListItem = ({ label, value }) => {
  return (
    <div className="d-flex flex-stack">
      <div className="text-gray-700 fw-semibold fs-6 me-2">{label}</div>
      <div className="d-flex align-items-senter">
        <span className="text-gray-900 fw-bolder fs-6">{value}</span>
      </div>
    </div>
  )
}

const ListSeparator = () => (
  <div className="separator separator-dashed my-2"></div>
);

WQLocation.displayName = WQLocation.name;
