import React, { useEffect } from 'react';

export const Home = function () {
    useEffect(() => {
        document.body.classList.add('bg-dark');
    }, []);

       return (
        <div></div>
    );
}

Home.displayName = Home.name;