const substitute = (authority) => {
  const url = authority.replace(/\{(\w+)\}/gi, (match, g1) => document.location[g1]);
  return url.endsWith(":") ? url.substring(0, url.length - 1) : url;  
}

export const getAuthorityUrl = () => {
  const authority = window.__RUNTIME_CONFIG__.OIDC_AUTHORITY;
  return substitute(authority);
}

export const getAppUrl = () => {
  const appUrl = window.__RUNTIME_CONFIG__.OIDC_APP_URL;
  return substitute(appUrl);
}

export const getTitle = () => {
  return window.__RUNTIME_CONFIG__.TITLE;
}