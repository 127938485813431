import React, { useCallback, useState } from 'react';
import { Col, Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarText, NavbarToggler, Row } from 'reactstrap';
import logo from '../images/logo-inverted.png';
import { WQLocation } from './WQLocation';
import './Dashboard.css';
import usePoll from '../hooks/usePoll';
import { useAuth } from 'react-oidc-context';
import { getAppUrl, getAuthorityUrl } from '../utils/env';

const appUri = getAppUrl();
const authorityUrl = getAuthorityUrl();

export const Dashboard = function () {

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const [servers, setServers] = useState([]);
    const [lastUpdate, setLastUpdated] = useState();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const auth = useAuth();

    const toggle = () => setIsNavbarOpen(!isNavbarOpen);

    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(`serverInfo`);
            const data = await response.json();
            setServers(data);
            setLastUpdated(new Date());
        } catch (ex) {
            console.error(ex);
        }
    }, []);

    usePoll(async () => {
        await fetchData();
    });

    const logout = useCallback(async () => {        
        await auth.signoutRedirect({ id_token_hint: auth.user.id_token, post_logout_redirect_uri: appUri });
        await auth.revokeTokens();
      }, [auth]);

    const isAdmin = useCallback(() => {
        const { role } = auth.user?.profile || {};
        return role === 'admin';
    }, [auth]);

    return (
        <>
            <Navbar
                className="mb-2"
                color="dark"
                dark
                fixed="top"
                expand="md"
                container="md"
            >
                <NavbarBrand href="/">
                    <img
                        alt="logo"
                        src={logo}
                        style={{
                            height: 40,
                            width: 40
                        }}
                    />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isNavbarOpen} navbar>
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <NavLink active href="/">Dashboard</NavLink>
                        </NavItem>
                        {isAdmin() && 
                        <NavItem>
                            <NavLink href={`${authorityUrl}/users`} target='_blank'>Users</NavLink>
                        </NavItem>
                        }
                    </Nav>
                    <Nav navbar>
                        <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle nav caret>
                                <i className="bi bi-person" style={{ fontSize: '1.1rem' }}></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>
                                    <i className="bi bi-person-circle" style={{ fontSize: '1.1rem' }}></i>
                                    <span className="ps-2">{auth.user?.profile.name}</span>                                    
                                </DropdownItem>
                                <DropdownItem tag='a' href={`${authorityUrl}/account/manage?returnUrl=${appUri}`}>Manage profile</DropdownItem>
                                <DropdownItem tag='a' href={`${authorityUrl}/account/changepassword?returnUrl=${appUri}`}>Change password</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </Collapse>
            </Navbar>
            <Container className="main-container">
                <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                    {servers.map(server => (
                        <Col key={server.ipAddress}>
                            <WQLocation
                                title={server.serverName}
                                ipAddress={server.ipAddress}
                                isOnline={server.isOnline}
                                totalWaiting={server.totalWaiting}
                                openCounters={server.openCounters}
                                totalFinished={server.totalFinished}
                                avgProcessedTime={server.avgProcessedTime} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
}

Dashboard.displayName = Dashboard.name;

